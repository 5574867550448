<template>
  <div class="auth-wrapper auth-v1 px-2">  
    <div class="auth-inner py-2">
		 <b-img
				v-if="gsite"	
				class="m-2"
				center
				:src="'https://www.buxida.com/admin/fichiers/logo/' + gsite[0].C_logo"
			/>
			<b-card 
				v-if="etapeencour == 1"	
				class="md-0"
			>
				<div class="text-center">
					<div class="h1 font-weight-bolder text-secondary my-md-0 ">
					BIENVENUE
					</div>
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					chez {{gsite[0].C_libelle}}
					</div>
					<div class="font-weight-bolder my-md-0 ">
					Réservation en ligne
					</div><br>
					<feather-icon 
						icon="CalendarIcon"
						size="25"
					/>
				</div>
				<br>
				<div v-if="affcentre">
					<div class="h4 font-weight-bolder text-uppercase">
						Sélectionnez votre centre
					</div>	
					<b-form-group>
						 <v-select
							v-model="selectedsite"
							label="title"
							:clearable="false"
							placeholder="Choisir un centre"
							:options="site"   
							v-on:input="getbox()"
						  />
					</b-form-group>				
				</div>
				<div class="h4 font-weight-bolder text-uppercase">
					Quand Souhaitez vous réserver votre box ?
				</div>		
				<b-input-group class="input-group-merge">
					<b-form-datepicker
						id="dateInput"
						v-model="datedebut"
						placeholder="Choisir une date"
						:min="min"
						:max="max"			   
						locale="fr"
						selected-variant="primary"
						hide-header
						class="mb-1"
						@context="onContext"
					/>
				</b-input-group>
				<hr>
				<div class="text-right">
					<b-button
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						type="submit"
						variant="primary"
						class="mr-1"
						@click="validdate"
						:disabled="!etape1ok"
					>
					  Suivant
					</b-button>
				</div>
			</b-card>
		    <b-card 
				v-if="etapeencour == 2"	
			>
				<div 
					v-if='boxdispo'>
					<div class="text-center">
						<feather-icon 
							icon="HomeIcon"
							size="25"
						/>
					</div>
					<div class="h4 font-weight-bolder text-uppercase">
						Je choisis mon box
					</div>	
					<b-form-group> 
						 <v-select
							v-model="selectedemplacement"
							label="title"
							:clearable="false"
							placeholder="Choisir un type de box"
							:options="emplacement"
							:disabled="!selectedsite"
							v-on:input="selectbox()"
						  />
					</b-form-group>
					<!-- <div 
						 class="h4 font-weight-bolder text-secondary my-md-0 "
						 v-if="C_numbox != ''"
					>
						Vous avez choisi le box <strong>{{this.C_numbox}}</strong> au tarif de {{this.I_prixTTC}} {{this.C_currency}} TTC
					</div><br> -->
					<div
						 v-if="affassurance"
					>
						<div class="h4 font-weight-bolder text-uppercase">
							Je choisis une assurance complémentaire 
						</div>	
						<div class="font-weight-bolder my-md-0 ">
							{{C_assurance}}
						</div>
						<b-form-group>
							 <v-select
								v-model="selectedassurance"
								label="title"
								placeholder="Choisir une assurance"
								:options="assurance"  
							  />
						</b-form-group>
					</div>
					<hr>
					<b-row offset-md="4" >
						<b-col class="text-left">
							<b-button
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								type="reset"
								variant="outline-secondary"
								@click="etapeencour=1"
							>
							  Precedent 
							</b-button>
						</b-col>
						<b-col class="text-right">
							<b-button
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								type="submit"
								variant="primary"
								class="mr-1"
								@click="goetape3"
							>
							  Suivant
							</b-button>
						</b-col>
					</b-row>
				</div>
				<div 
					v-else>
					<div class="h4 font-weight-bolder text-center">
						Nous n'avons plus de box disponible actuellement pour votre demande
					</div>
					<br>
					<b-button 
						v-if="baffautrebox"
						v-ripple.400="'rgba(186, 191, 199, 0.15)'"
						variant="primary"
						block
						@click="afftoutbox"
					>
					  Voir d’autres types de box disponibles
					</b-button>
					<br>
					<div class="text-center">
						N'hésitez pas à nous contacter pour plus d'information
					</div>	
					
					<hr>
					<b-row offset-md="4" >
						<b-col class="text-left">
							<b-button
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								type="reset"
								variant="outline-secondary"
								@click="etapeencour=1"
							>
							  Precedent 
							</b-button>
						</b-col>
						<b-col class="text-right">
						</b-col>
					</b-row>
				</div>
			</b-card>
			<b-card 
				v-if="etapeencour == 3"
			>
				<div class="text-center">
					<feather-icon 
						icon="EditIcon"
						size="25"
					/>
				</div>
				<div class="h4 font-weight-bolder text-uppercase">
					Merci de saisir vos coordonées
				</div>	
				<b-form @submit.prevent>
					<b-row>
						<b-col cols="3">
						<b-form-checkbox
						  v-model="bsoc"
						  value="1"
						  class="custom-control-primary"
						>
						  Société
						</b-form-checkbox>
						</b-col>
						<b-col cols="9">
							<b-form-group
							  v-if="bsoc"
							  label="Raison sociale"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								  <feather-icon icon="HomeIcon" />
								</b-input-group-prepend>
								<b-form-input
									v-model="CRS"
									id="hi-rs"
									placeholder="Raison sociale"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row 	v-if="bsoc">
						<b-col cols="6">
							<b-form-group
							  label="SIRET"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								</b-input-group-prepend>
								<b-form-input
									v-model="CSIRET"
									id="hi-rs"
									placeholder="N° Siret"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group
							  label="N° TVA"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								</b-input-group-prepend>
								<b-form-input
									v-model="CTVA"
									id="hi-rs"
									placeholder="N° de TVA intracommunautaire"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
					</b-row><br>
					<b-row>
						<b-col cols="6">
							<b-form-group
								label="Civilité"
								label-for="hi-civ"
								 label-cols-md="3"
							>
							  <v-select
								id="hi-civ"
								v-model="selectcivilite"
								label="name"
								placeholder="Choisir une civilité"
								:options="civilite"   
							  />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
					  <b-col cols="6">
						<b-form-group
						  label="Nom"
						  label-for="hi-nom"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CNOM"
								id="hi-nom"
								placeholder="Nom"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
							label="Prénom"
							label-for="hi-prenom"
							label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CPRENOM"
								id="hi-prenom"
								placeholder="Prénom"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
						  label="Email"
						  label-for="hi-email"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="MailIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CEMAIL"
								id="hi-email"
								type="email"
								placeholder="Email"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
						  label="Mobile"
						  label-for="hi-number"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="SmartphoneIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CPORT"
								id="hi-number"
								placeholder="Mobile"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="12">
						<b-form-group
						  label="Adresse"
						  label-for="hi-adresse"
						>
						  <b-input-group class="input-group-merge">
							<b-form-input
								v-model="CADRESSE"
								id="hi-adresse"
								placeholder="Adresse"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="3">
						<b-form-group
						  label="Code postal"
						  label-for="hi-cp"
						>
						  <b-input-group class="input-group-merge">
							<b-form-input
								v-model="CCP"
								id="hi-cp"
								placeholder="Code postal"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="4">
						<b-form-group
						  label="Ville"
						  label-for="hi-ville"
						>
						  <b-form-input
							v-model="CVILLE"
							id="hi-ville"
							placeholder="Ville"
							/>
						</b-form-group>
					  </b-col>
					  <b-col cols="5">
						<b-form-group
						  label="Pays"
						  label-for="hi-Pays"
						>
						  <v-select
							id="hi-Pays"
							v-model="selectedpays"
							label="name"
							placeholder="Choisir un pays"
							:options="pays"   
						  />
						</b-form-group>
					  </b-col>
					</b-row>
				</b-form>
				<hr>
				<b-row offset-md="4" >
				<b-col class="text-left">
					<b-button
						v-ripple.400="'rgba(186, 191, 199, 0.15)'"
						type="reset"
						variant="outline-secondary"
						@click="etapeencour=2"
					>
					  Precedent 
					</b-button>
				</b-col>
				<b-col class="text-right">
					<b-button
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						type="submit"
						variant="primary"
						class="mr-1"
						@click="goetape4"
					>
					  Suivant
					</b-button>
				</b-col>
			</b-row>
			</b-card>
			<b-card 
				v-if="etapeencour == 4"
			>
				<div class="text-center">
					<feather-icon 
						icon="ListIcon"
						size="25"
					/>
					</div>
					<div class="h4 font-weight-bolder text-uppercase">
						Récapitulatif
					</div>	
					<b-row offset-md="4" >
						<b-col>
							<b-card
								border-variant="secondary"
								bg-variant="secondary"
								class="shadow-none"
							>
								<span class="h5 font-weight-bolder text-white text-uppercase">
									Début de location 
								</span><br>	
								<span class="text-white-50">
									{{libdatedebut}}
								</span><br>
								<span class="h5 font-weight-bolder text-white text-uppercase">
									site
								</span><br>
								<span class="text-white-50">
									 {{selectedsite.title}}
								</span>
							</b-card>
						</b-col>
 						<b-col>
							<b-card
								border-variant="secondary"
								bg-variant="secondary"
								class="shadow-none"
							>
								<div class="h5 font-weight-bolder text-white text-uppercase">
									Vos informations
								</div>
								<span v-if="bsoc" class="text-white-50">
									{{CRS}}<br>
									N° Siret : {{CSIRET}}<br>
									N° TVA : {{CTVA}}<br>
								</span>
								<span class="text-white-50">
									{{CNOM}} {{CPRENOM}}<br>
									{{CADRESSE}}<br> 
									{{CCP}} {{CVILLE}} {{selectedpays.name}}<br>
									{{CEMAIL}}
									{{CPORT}}
								</span>
							</b-card>
						</b-col>
						<b-col>
							<b-card
								border-variant="secondary"
								bg-variant="secondary"
								class="shadow-none"
							>
								<div class="h5 font-weight-bolder text-white text-uppercase">
									Votre box
								</div>
								<div class="text-white-50">
									 {{C_numbox}} <br> {{I_prixTTC}}{{infobox.C_currency}} TTC
								</div>
								<div class="text-white-50">
									<small>
										{{selectedemplacement.I_tailleM2}} M<sup class="text-white-50">2</sup> - {{selectedemplacement.I_tailleM3}} M<sup class="text-white-50">3</sup>
									</small>
								</div>
								<div 
									 v-if="selectedassurance"
									 class="h5 font-weight-bolder text-white text-uppercase">
									<br>
									Assurance
								</div>
								<div 
									v-if="selectedassurance"
									class="text-white-50"
								>
									 {{libassurance}} <br> {{ttcassurance}}{{infobox.C_currency}} TTC
								</div>
							</b-card>
								<div class="bg-light-primary rounded align-text-bottom border">
									<b-badge
									variant="light-primary"
									
									class="text-left"
									>
									Total
									</b-badge>
									<div class="text-right">
										<h2 class="d-inline mr-25">
											{{totalmensuelTTC}}
										</h2>
										<sup class="text-body">
										<small>{{infobox.C_currency}} ttc</small>
										</sup>
										<sub class="text-body mr-1"><small>/ mois</small></sub>
									</div>
								</div>
								<p><em><small>soit {{totalmensuelHT}}{{infobox.C_currency}} HT (TVA : {{totalmensuelTVA}}{{infobox.C_currency}})</small></em></p>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="12">
								<h6
									v-if="infobox.Bpay && !infobox.B_cautionfixe"	
								>
									Vous serez ensuite amené à payer la caution de de <strong>{{this.selectedemplacement.I_prixTTC}}{{this.infobox.C_currency}} TTC</strong></h6>
								<h6
									v-if="infobox.Bpay && infobox.B_cautionfixe"	
								>
									Vous serez ensuite amené à payer la caution de de <strong>{{this.infobox.I_cautionfixe}}{{this.infobox.C_currency}} TTC</strong></h6>
							<b-form-checkbox
							  v-model="Bcgv"
							  name="check-button"
							  switch
							  inline
							>J’accepte les <a 
							   href="#" 
							   @click="openurl(urlcgv)"
							 >conditions générales de vente</a>	  
							</b-form-checkbox>
						</b-col>
					</b-row>
					<hr v-if="bcode && !bcodeok">
					<div class="h5" v-if="bcode && !bcodeok">
						Par mesure de sécurité, Merci de saisir le code reçu sur par e-mail
					</div>
					<b-form v-if="bcode && !bcodeok">
						<b-row  >
							<b-col cols="6">
								  <b-input-group class="input-group-merge">
									<b-input-group-prepend is-text>
									  <feather-icon icon="MailIcon" />
									</b-input-group-prepend>
									<b-form-input
										v-model="CEMAIL"
										id="hi-email"
										type="email"
										placeholder="Email"
									/>
								  </b-input-group>
							</b-col>
							<b-col cols="6">
								<b-button
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								variant="outline-secondary"
								@click="sendcode"
								>
								  Renvoyer le code 
								</b-button>
							</b-col>
						</b-row>
						<br>
						<b-row >
							<b-col cols="3">
								<b-input-group class="input-group-merge">
									<b-input-group-prepend is-text>
									  <feather-icon icon="ShieldIcon" />
									</b-input-group-prepend>
									<b-form-input
										v-model="CCODE"
										id="hi-code"
										placeholder="0000"
									/>
								</b-input-group>
							</b-col>
							<b-col cols="6" class="text-left">
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									variant="primary"
									class="mr-1"
									@click="verifcode"
								>
								  Valider mon e-mail
								</b-button>
							</b-col>
						</b-row>
					</b-form>
					<hr>
					<b-row offset-md="4" >
						<b-col class="text-left">
							<b-button
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								type="reset"
								variant="outline-secondary"
								@click="etapeencour=3"
							>
							  Precedent 
							</b-button>
						</b-col>
						<b-col class="text-right">
							<b-button
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								type="submit"
								variant="primary"
								class="mr-1"
								@click="addresa"
							>
							  Suivant
							</b-button>
						</b-col>
					</b-row>	
			</b-card>
			<b-card 
				v-if="etapeencour == 5"
			>
				<h6>Préparation du paiement</h6>
				<div 
					 v-if="affrelancegostripe">
					<h6><strong>Erreur lors de demande</strong></h6>
					<b-button
						@click="goetape5"
					>Relancer la demande</b-button>
				</div>	
			</b-card>
			<b-card 
				v-if="etapeencour == 6"
			>
				<h6>Paiement de la caution de <strong>{{this.caution}}{{this.infobox.C_currency}}</strong></h6>
				<stripe-element-payment
				  ref="paymentRef"
				  :pk="pk"
				  :elements-options="elementsOptions"
				  :confirm-params="confirmParams"
				/><br>
				<b-button @click="pay">Payer {{this.caution}}{{this.infobox.C_currency}}</b-button>
			</b-card>
			<b-card>
				<b-progress
					:max="maxetape"
					animated
					variant="secondary"
				>	
				<b-progress-bar :value="etapeencour" class="demo-vertical-spacing">
					  <strong> Etape {{ etapeencour }} sur {{ maxetape }}</strong>
					</b-progress-bar>
				</b-progress>
			</b-card>
		<small class="text-muted">{{ version_resa }}</small>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BCardTitle, BLink, BFormGroup, BImg, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol, BFormDatepicker, BProgress, BProgressBar, BBadge } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
	
export default {
	components: {
		BCard,
		BCardText,
		BCardTitle,
		BLink,
		BFormGroup,
		vSelect,
		BImg,
		BRow,
		BCol,
		BFormInput,
		BFormCheckbox,
		BInputGroup,
		BInputGroupPrepend,
		BForm,
		BButton,
		BFormDatepicker,
		BProgress, 
		BProgressBar,
		BBadge,
		StripeElementPayment
	},
	directives: {
		Ripple,
	},
	data() {
		const now = new Date()
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
		const minDate = new Date(today)
		return {
			affcentre: true,
			totalapaye: 0,
			version_resa: 'V1.1.6',
			UIC: '',
			UIS: '',
			pk: '',
			livemode: false,
			elementsOptions: {
				appearance: {}, // appearance options
			},
			confirmParams: {
				return_url: '#', // success url
			},
			libdatedebut:'',
			cution: 0,
			totalmensuelTTC: 0,
			totalmensuelHT: 0,
			totalmensuelTVA: 0,
			urlcgv: '',
			libassurance: '',	
			currencyassurance: '',	
			ttcassurance: '',	
			Bcgv: false,
			C_numbox: '',
			I_prixTTC: '',
			C_currency: '',
			C_assurance: '',
			affassurance: false,
			etape1ok: false,
			etapeencour: 1,
			maxetape: 6,
			bsoc: false,
			boxdispo: false,
			baffautrebox: true,
			affrelancegostripe: false,
			CRS: '',
			CSIRET: '',
			CTVA: '',
			CNOM: '',
			CPRENOM: '',
			CADRESSE: '',
			CCP: '',
			CVILLE: '',
			CPORT: '',
			CEMAIL: '',
			CCIVILITE: '',
			CCODE: '',
			bcode: false,
			bcodeok: false,
			MMESS: '',
			datedebut: '',
			datedebutcontrat: '',
			min: minDate,
			max: minDate,
			selectedsite: null,
			site: [],
			selectedemplacement: null,
			emplacement: [],
			selectedassurance: null,
			assurance: [],
			gsite: [],
			infobox: [],
			selectedpays: {"name": "France","code": "FR"},
			civilite: [{
					"name": "NC",
					"id": "1"
				},
				{
					"name": "Monsieur",
					"id": "4"
				},
				{
					"name": "Madame",
					"id": "2"
				},
				{
					"name": "Mademoiselle",
					"id": "3"
				},
			],
			selectcivilite: {"name": "NC","id": "1"},
			pays: [{
				"name": "Afghanistan",
				"code": "AF"
			}, {
				"name": "Åland Islands",
				"code": "AX"
			}, {
				"name": "Albania",
				"code": "AL"
			}, {
				"name": "Algeria",
				"code": "DZ"
			}, {
				"name": "American Samoa",
				"code": "AS"
			}, {
				"name": "Andorra",
				"code": "AD"
			}, {
				"name": "Angola",
				"code": "AO"
			}, {
				"name": "Anguilla",
				"code": "AI"
			}, {
				"name": "Antarctica",
				"code": "AQ"
			}, {
				"name": "Antigua and Barbuda",
				"code": "AG"
			}, {
				"name": "Argentina",
				"code": "AR"
			}, {
				"name": "Armenia",
				"code": "AM"
			}, {
				"name": "Aruba",
				"code": "AW"
			}, {
				"name": "Australia",
				"code": "AU"
			}, {
				"name": "Austria",
				"code": "AT"
			}, {
				"name": "Azerbaijan",
				"code": "AZ"
			}, {
				"name": "Bahamas",
				"code": "BS"
			}, {
				"name": "Bahrain",
				"code": "BH"
			}, {
				"name": "Bangladesh",
				"code": "BD"
			}, {
				"name": "Barbados",
				"code": "BB"
			}, {
				"name": "Belarus",
				"code": "BY"
			}, {
				"name": "Belgium",
				"code": "BE"
			}, {
				"name": "Belize",
				"code": "BZ"
			}, {
				"name": "Benin",
				"code": "BJ"
			}, {
				"name": "Bermuda",
				"code": "BM"
			}, {
				"name": "Bhutan",
				"code": "BT"
			}, {
				"name": "Bolivia, Plurinational State of",
				"code": "BO"
			}, {
				"name": "Bonaire, Sint Eustatius and Saba",
				"code": "BQ"
			}, {
				"name": "Bosnia and Herzegovina",
				"code": "BA"
			}, {
				"name": "Botswana",
				"code": "BW"
			}, {
				"name": "Bouvet Island",
				"code": "BV"
			}, {
				"name": "Brazil",
				"code": "BR"
			}, {
				"name": "British Indian Ocean Territory",
				"code": "IO"
			}, {
				"name": "Brunei Darussalam",
				"code": "BN"
			}, {
				"name": "Bulgaria",
				"code": "BG"
			}, {
				"name": "Burkina Faso",
				"code": "BF"
			}, {
				"name": "Burundi",
				"code": "BI"
			}, {
				"name": "Cambodia",
				"code": "KH"
			}, {
				"name": "Cameroon",
				"code": "CM"
			}, {
				"name": "Canada",
				"code": "CA"
			}, {
				"name": "Cape Verde",
				"code": "CV"
			}, {
				"name": "Cayman Islands",
				"code": "KY"
			}, {
				"name": "Central African Republic",
				"code": "CF"
			}, {
				"name": "Chad",
				"code": "TD"
			}, {
				"name": "Chile",
				"code": "CL"
			}, {
				"name": "China",
				"code": "CN"
			}, {
				"name": "Christmas Island",
				"code": "CX"
			}, {
				"name": "Cocos (Keeling) Islands",
				"code": "CC"
			}, {
				"name": "Colombia",
				"code": "CO"
			}, {
				"name": "Comoros",
				"code": "KM"
			}, {
				"name": "Congo",
				"code": "CG"
			}, {
				"name": "Congo, the Democratic Republic of the",
				"code": "CD"
			}, {
				"name": "Cook Islands",
				"code": "CK"
			}, {
				"name": "Costa Rica",
				"code": "CR"
			}, {
				"name": "Côte d'Ivoire",
				"code": "CI"
			}, {
				"name": "Croatia",
				"code": "HR"
			}, {
				"name": "Cuba",
				"code": "CU"
			}, {
				"name": "Curaçao",
				"code": "CW"
			}, {
				"name": "Cyprus",
				"code": "CY"
			}, {
				"name": "Czech Republic",
				"code": "CZ"
			}, {
				"name": "Denmark",
				"code": "DK"
			}, {
				"name": "Djibouti",
				"code": "DJ"
			}, {
				"name": "Dominica",
				"code": "DM"
			}, {
				"name": "Dominican Republic",
				"code": "DO"
			}, {
				"name": "Ecuador",
				"code": "EC"
			}, {
				"name": "Egypt",
				"code": "EG"
			}, {
				"name": "El Salvador",
				"code": "SV"
			}, {
				"name": "Equatorial Guinea",
				"code": "GQ"
			}, {
				"name": "Eritrea",
				"code": "ER"
			}, {
				"name": "Estonia",
				"code": "EE"
			}, {
				"name": "Ethiopia",
				"code": "ET"
			}, {
				"name": "Falkland Islands (Malvinas)",
				"code": "FK"
			}, {
				"name": "Faroe Islands",
				"code": "FO"
			}, {
				"name": "Fiji",
				"code": "FJ"
			}, {
				"name": "Finland",
				"code": "FI"
			}, {
				"name": "France",
				"code": "FR"
			}, {
				"name": "French Guiana",
				"code": "GF"
			}, {
				"name": "French Polynesia",
				"code": "PF"
			}, {
				"name": "French Southern Territories",
				"code": "TF"
			}, {
				"name": "Gabon",
				"code": "GA"
			}, {
				"name": "Gambia",
				"code": "GM"
			}, {
				"name": "Georgia",
				"code": "GE"
			}, {
				"name": "Germany",
				"code": "DE"
			}, {
				"name": "Ghana",
				"code": "GH"
			}, {
				"name": "Gibraltar",
				"code": "GI"
			}, {
				"name": "Greece",
				"code": "GR"
			}, {
				"name": "Greenland",
				"code": "GL"
			}, {
				"name": "Grenada",
				"code": "GD"
			}, {
				"name": "Guadeloupe",
				"code": "GP"
			}, {
				"name": "Guam",
				"code": "GU"
			}, {
				"name": "Guatemala",
				"code": "GT"
			}, {
				"name": "Guernsey",
				"code": "GG"
			}, {
				"name": "Guinea",
				"code": "GN"
			}, {
				"name": "Guinea-Bissau",
				"code": "GW"
			}, {
				"name": "Guyana",
				"code": "GY"
			}, {
				"name": "Haiti",
				"code": "HT"
			}, {
				"name": "Heard Island and McDonald Islands",
				"code": "HM"
			}, {
				"name": "Holy See (Vatican City State)",
				"code": "VA"
			}, {
				"name": "Honduras",
				"code": "HN"
			}, {
				"name": "Hong Kong",
				"code": "HK"
			}, {
				"name": "Hungary",
				"code": "HU"
			}, {
				"name": "Iceland",
				"code": "IS"
			}, {
				"name": "India",
				"code": "IN"
			}, {
				"name": "Indonesia",
				"code": "ID"
			}, {
				"name": "Iran, Islamic Republic of",
				"code": "IR"
			}, {
				"name": "Iraq",
				"code": "IQ"
			}, {
				"name": "Ireland",
				"code": "IE"
			}, {
				"name": "Isle of Man",
				"code": "IM"
			}, {
				"name": "Israel",
				"code": "IL"
			}, {
				"name": "Italy",
				"code": "IT"
			}, {
				"name": "Jamaica",
				"code": "JM"
			}, {
				"name": "Japan",
				"code": "JP"
			}, {
				"name": "Jersey",
				"code": "JE"
			}, {
				"name": "Jordan",
				"code": "JO"
			}, {
				"name": "Kazakhstan",
				"code": "KZ"
			}, {
				"name": "Kenya",
				"code": "KE"
			}, {
				"name": "Kiribati",
				"code": "KI"
			}, {
				"name": "Korea, Democratic People's Republic of",
				"code": "KP"
			}, {
				"name": "Korea, Republic of",
				"code": "KR"
			}, {
				"name": "Kuwait",
				"code": "KW"
			}, {
				"name": "Kyrgyzstan",
				"code": "KG"
			}, {
				"name": "Lao People's Democratic Republic",
				"code": "LA"
			}, {
				"name": "Latvia",
				"code": "LV"
			}, {
				"name": "Lebanon",
				"code": "LB"
			}, {
				"name": "Lesotho",
				"code": "LS"
			}, {
				"name": "Liberia",
				"code": "LR"
			}, {
				"name": "Libya",
				"code": "LY"
			}, {
				"name": "Liechtenstein",
				"code": "LI"
			}, {
				"name": "Lithuania",
				"code": "LT"
			}, {
				"name": "Luxembourg",
				"code": "LU"
			}, {
				"name": "Macao",
				"code": "MO"
			}, {
				"name": "Macedonia, the Former Yugoslav Republic of",
				"code": "MK"
			}, {
				"name": "Madagascar",
				"code": "MG"
			}, {
				"name": "Malawi",
				"code": "MW"
			}, {
				"name": "Malaysia",
				"code": "MY"
			}, {
				"name": "Maldives",
				"code": "MV"
			}, {
				"name": "Mali",
				"code": "ML"
			}, {
				"name": "Malta",
				"code": "MT"
			}, {
				"name": "Marshall Islands",
				"code": "MH"
			}, {
				"name": "Martinique",
				"code": "MQ"
			}, {
				"name": "Mauritania",
				"code": "MR"
			}, {
				"name": "Mauritius",
				"code": "MU"
			}, {
				"name": "Mayotte",
				"code": "YT"
			}, {
				"name": "Mexico",
				"code": "MX"
			}, {
				"name": "Micronesia, Federated States of",
				"code": "FM"
			}, {
				"name": "Moldova, Republic of",
				"code": "MD"
			}, {
				"name": "Monaco",
				"code": "MC"
			}, {
				"name": "Mongolia",
				"code": "MN"
			}, {
				"name": "Montenegro",
				"code": "ME"
			}, {
				"name": "Montserrat",
				"code": "MS"
			}, {
				"name": "Morocco",
				"code": "MA"
			}, {
				"name": "Mozambique",
				"code": "MZ"
			}, {
				"name": "Myanmar",
				"code": "MM"
			}, {
				"name": "Namibia",
				"code": "NA"
			}, {
				"name": "Nauru",
				"code": "NR"
			}, {
				"name": "Nepal",
				"code": "NP"
			}, {
				"name": "Netherlands",
				"code": "NL"
			}, {
				"name": "New Caledonia",
				"code": "NC"
			}, {
				"name": "New Zealand",
				"code": "NZ"
			}, {
				"name": "Nicaragua",
				"code": "NI"
			}, {
				"name": "Niger",
				"code": "NE"
			}, {
				"name": "Nigeria",
				"code": "NG"
			}, {
				"name": "Niue",
				"code": "NU"
			}, {
				"name": "Norfolk Island",
				"code": "NF"
			}, {
				"name": "Northern Mariana Islands",
				"code": "MP"
			}, {
				"name": "Norway",
				"code": "NO"
			}, {
				"name": "Oman",
				"code": "OM"
			}, {
				"name": "Pakistan",
				"code": "PK"
			}, {
				"name": "Palau",
				"code": "PW"
			}, {
				"name": "Palestine, State of",
				"code": "PS"
			}, {
				"name": "Panama",
				"code": "PA"
			}, {
				"name": "Papua New Guinea",
				"code": "PG"
			}, {
				"name": "Paraguay",
				"code": "PY"
			}, {
				"name": "Peru",
				"code": "PE"
			}, {
				"name": "Philippines",
				"code": "PH"
			}, {
				"name": "Pitcairn",
				"code": "PN"
			}, {
				"name": "Poland",
				"code": "PL"
			}, {
				"name": "Portugal",
				"code": "PT"
			}, {
				"name": "Puerto Rico",
				"code": "PR"
			}, {
				"name": "Qatar",
				"code": "QA"
			}, {
				"name": "Réunion",
				"code": "RE"
			}, {
				"name": "Romania",
				"code": "RO"
			}, {
				"name": "Russian Federation",
				"code": "RU"
			}, {
				"name": "Rwanda",
				"code": "RW"
			}, {
				"name": "Saint Barthélemy",
				"code": "BL"
			}, {
				"name": "Saint Helena, Ascension and Tristan da Cunha",
				"code": "SH"
			}, {
				"name": "Saint Kitts and Nevis",
				"code": "KN"
			}, {
				"name": "Saint Lucia",
				"code": "LC"
			}, {
				"name": "Saint Martin (French part)",
				"code": "MF"
			}, {
				"name": "Saint Pierre and Miquelon",
				"code": "PM"
			}, {
				"name": "Saint Vincent and the Grenadines",
				"code": "VC"
			}, {
				"name": "Samoa",
				"code": "WS"
			}, {
				"name": "San Marino",
				"code": "SM"
			}, {
				"name": "Sao Tome and Principe",
				"code": "ST"
			}, {
				"name": "Saudi Arabia",
				"code": "SA"
			}, {
				"name": "Senegal",
				"code": "SN"
			}, {
				"name": "Serbia",
				"code": "RS"
			}, {
				"name": "Seychelles",
				"code": "SC"
			}, {
				"name": "Sierra Leone",
				"code": "SL"
			}, {
				"name": "Singapore",
				"code": "SG"
			}, {
				"name": "Sint Maarten (Dutch part)",
				"code": "SX"
			}, {
				"name": "Slovakia",
				"code": "SK"
			}, {
				"name": "Slovenia",
				"code": "SI"
			}, {
				"name": "Solomon Islands",
				"code": "SB"
			}, {
				"name": "Somalia",
				"code": "SO"
			}, {
				"name": "South Africa",
				"code": "ZA"
			}, {
				"name": "South Georgia and the South Sandwich Islands",
				"code": "GS"
			}, {
				"name": "South Sudan",
				"code": "SS"
			}, {
				"name": "Spain",
				"code": "ES"
			}, {
				"name": "Sri Lanka",
				"code": "LK"
			}, {
				"name": "Sudan",
				"code": "SD"
			}, {
				"name": "Suriname",
				"code": "SR"
			}, {
				"name": "Svalbard and Jan Mayen",
				"code": "SJ"
			}, {
				"name": "Swaziland",
				"code": "SZ"
			}, {
				"name": "Sweden",
				"code": "SE"
			}, {
				"name": "Switzerland",
				"code": "CH"
			}, {
				"name": "Syrian Arab Republic",
				"code": "SY"
			}, {
				"name": "Taiwan, Province of China",
				"code": "TW"
			}, {
				"name": "Tajikistan",
				"code": "TJ"
			}, {
				"name": "Tanzania, United Republic of",
				"code": "TZ"
			}, {
				"name": "Thailand",
				"code": "TH"
			}, {
				"name": "Timor-Leste",
				"code": "TL"
			}, {
				"name": "Togo",
				"code": "TG"
			}, {
				"name": "Tokelau",
				"code": "TK"
			}, {
				"name": "Tonga",
				"code": "TO"
			}, {
				"name": "Trinidad and Tobago",
				"code": "TT"
			}, {
				"name": "Tunisia",
				"code": "TN"
			}, {
				"name": "Turkey",
				"code": "TR"
			}, {
				"name": "Turkmenistan",
				"code": "TM"
			}, {
				"name": "Turks and Caicos Islands",
				"code": "TC"
			}, {
				"name": "Tuvalu",
				"code": "TV"
			}, {
				"name": "Uganda",
				"code": "UG"
			}, {
				"name": "Ukraine",
				"code": "UA"
			}, {
				"name": "United Arab Emirates",
				"code": "AE"
			}, {
				"name": "United Kingdom",
				"code": "GB"
			}, {
				"name": "United States",
				"code": "US"
			}, {
				"name": "United States Minor Outlying Islands",
				"code": "UM"
			}, {
				"name": "Uruguay",
				"code": "UY"
			}, {
				"name": "Uzbekistan",
				"code": "UZ"
			}, {
				"name": "Vanuatu",
				"code": "VU"
			}, {
				"name": "Venezuela, Bolivarian Republic of",
				"code": "VE"
			}, {
				"name": "Viet Nam",
				"code": "VN"
			}, {
				"name": "Virgin Islands, British",
				"code": "VG"
			}, {
				"name": "Virgin Islands, U.S.",
				"code": "VI"
			}, {
				"name": "Wallis and Futuna",
				"code": "WF"
			}, {
				"name": "Western Sahara",
				"code": "EH"
			}, {
				"name": "Yemen",
				"code": "YE"
			}, {
				"name": "Zambia",
				"code": "ZM"
			}, {
				"name": "Zimbabwe",
				"code": "ZW"
			}]
		}
	},
	methods: {
		pay () {
		  this.$refs.paymentRef.submit();
		},
		selectbox() {
			this.C_numbox = this.selectedemplacement.libemplacement
			this.I_prixTTC = this.selectedemplacement.I_prixTTC
		},
		getbox() {
			//console.log(this.selectedsite.title)
			//console.log(this.selectedsite.UI)
			
			this.etape1ok = false
			const UIUSER = this.UI
			this.$http.post(this.APIURL + 'resa/getbox',{ UIR: UIUSER, UIS: this.selectedsite.UI })
			.then(response => {
				if (response.data.res === 1) {
					this.emplacement = response.data.emplacement
					this.selectedemplacement = null
					this.etape1ok = true
					if (this.emplacement.length != 0 ) {
						this.boxdispo = true
					} else {
						this.$http.post(this.APIURL + 'resa/boxnondispo',{ UIR: UIUSER, UIS: this.selectedsite.UI })
						.then(response => {
							if (response.data.res != 1) {
								this.$toast({
										component: ToastificationContent,
										props: {
											title: 'Oups',
											icon: 'SmileIcon',
											variant: 'primary',
											text: 'Erreur connexion serveur',
										},
									},
									{
										timeout: 4000,
										position: 'top-center',
									})	
							}
						})
					}
				}	
			}) 
		},	
		afftoutbox() {
			// console.log(this.selectedsite.value)
			//console.log(this.selectedsite.state)
			this.etape1ok = false
			const UIUSER = this.UI
			this.$http.post(this.APIURL + 'resa/getboxall',{ UIR: UIUSER, UIS: this.selectedsite.UI })
			.then(response => {
				if (response.data.res === 1) {
					this.baffautrebox = false
					this.emplacement = response.data.emplacement
					this.selectedemplacement = null
					this.etape1ok = true
					if (this.emplacement.length != 0 ) {
						this.boxdispo = true
					} else {
						this.$http.post(this.APIURL + 'resa/boxnondispo2',{ UIR: UIUSER, UIS: this.selectedsite.UI })
						.then(response => {
							if (response.data.res != 1) {
								this.$toast({
										component: ToastificationContent,
										props: {
											title: 'Oups',
											icon: 'SmileIcon',
											variant: 'primary',
											text: 'Erreur connexion serveur',
										},
									},
									{
										timeout: 4000,
										position: 'top-center',
									})	
							}
						})
					}
				}	
			}) 
		},	
		getbox2() {
			this.$http.post(this.APIURL + 'resa/getbox2',{ UIS: this.selectedsite.UI })
			.then(response => {
				if (response.data.res === 1) {
					this.infobox = response.data.infobox[0]
					const numberOfDaysToAdd = this.infobox.I_nbjour
					const now = new Date()
					const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
					const maxDate = new Date(today)
					// maxDate.setMonth(maxDate.getMonth() + 2)
					maxDate.setDate(maxDate.getDate() + numberOfDaysToAdd)
					this.max = maxDate
					this.affassurance = response.data.infobox[0].B_assurance
					this.C_assurance = decodeURIComponent(escape(response.data.infobox[0].C_assurance))
					if (this.affassurance) {
						this.$http.post(this.APIURL + 'resa/getassurance',{ UIR: UIUSER, UIS: this.selectedsite.UI })
						.then(response => {
							if (response.data.res === 1) {
								this.assurance = [];
								for(var i = 0; i < response.data.assurance.length; i++)
								{
									this.assurance.push({
										title: decodeURIComponent(escape(response.data.assurance[i].C_libelle)) + " " + response.data.assurance[i].I_prixTTC + " " + response.data.assurance[i].C_currency + " TTC",
										UI: response.data.assurance[i].C_UI,
										I_prix_HT: response.data.assurance[i].I_prix_HT,
										I_prixTTC: response.data.assurance[i].I_prixTTC,
									})
								}
								this.selectedassurance = null
							}	
						}) 
					}
				}	
			}) 
		},
		validdate() {
			if (this.datedebut) {
				this.libdatedebut = this.datedebut.split("-").reverse().join("/")
				this.etapeencour = 2	
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir une date',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})	
			}
				
		},
		goetape3() {
			if (this.selectedemplacement) {
				if (this.selectedassurance) {
					this.libassurance = this.selectedassurance.C_libelle	
					this.currencyassurance  = this.selectedassurance.C_currency	
					this.ttcassurance = this.selectedassurance.I_prix_TTC
				} else {
					this.libassurance = ''	
					this.currencyassurance  = ''
					this.ttcassurance =''
				}
				this.etapeencour = 3	
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir un box',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})	
			}
				
		},	
		goetape4() {
			let berr = false
			let UIASS = ''
			this.totalmensuel = 0 // parseFloat(this.I_prixTTC) + parseFloat(this.ttcassurance)
			if ((this.CNOM === '' ) || (this.CPRENOM === '' ) || (this.CPORT === '' ) || (this.CEMAIL === '' ) || (this.CADRESSE === '' ) || (this.CCP === '' ) || (this.CVILLE === '' )) {
				berr = true	
			}
			if (this.bsoc) {
				if ((this.CRS === '' ) || (this.CSIRET === '' )) {
					berr = true	
				}
			}
			if (this.selectedassurance != null) {
				UIASS =	this.selectedassurance.UI
			}
			this.$http.post(this.APIURL + 'resa/getresume',{ UIE: this.selectedemplacement.UI, UIASS })
			.then(response => {
				if (response.data.res === 1) {
					this.totalmensuelTTC = parseFloat(response.data.I_prixTTC).toFixed(2)
					this.totalmensuelHT = response.data.I_prixHT
					this.totalmensuelTVA = response.data.I_prixTVA
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Erreur de connexion du serveur',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				}
			}) 
			if (!berr) {
				if ((this.bcode) && (!this.bcodeok)) {
					this.sendcode()	
				}
				this.etapeencour = 4	
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir tout les champs',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})		
			}
		},	
		addresa() {
			var berr = false
			if (!this.Bcgv) {
				berr = true	
			}
			if ((this.bcode) && (!this.bcodeok)){
				berr = true	
			}
			if (!berr) {
				let UIASS = ''
				if (this.selectedassurance != null) {
					UIASS =	this.selectedassurance.UI
				}
				this.$http.post(this.APIURL + 'resa/addresa',{ 
					UIR: this.UI, 
					UIS: this.selectedsite.UI, 
					UIE: this.selectedemplacement.UI, 
					UIASS: UIASS, 
					bsociete: this.bsoc, 
					CRS: this.CRS,
					CSIRET: this.CSIRET,
					CTVA: this.CTVA,
					CNOM: this.CNOM,
					CPRENOM: this.CPRENOM,
					CPORT: this.CPORT,
					CEMAIL: this.CEMAIL,
					CADRESSE: this.CADRESSE,
					CCP: this.CCP,
					CVILLE: this.CVILLE,
					CPAYS: this.selectedpays.code,
					MMESS: this.MMESS,
					IDCIV: this.selectcivilite.id,
					datedebut: this.datedebut,
				})
				.then(response => {
					if (response.data.res === 1) {
						if(response.data.Bstripe == "1") {
							//window.location.replace("https://resa.buxida.com/php/stripe/resa.html?UIC=" + response.data.CONCUI);
							this.UIC = response.data.CONCUI
							this.totalapaye = 0
							//this.elementsOptions.currency = 'eur' //this.infobox.C_currency
							if (this.infobox.B_cautionfixe == false) {
								this.caution = this.selectedemplacement.I_prixTTC
								this.totalapaye = this.selectedemplacement.I_prixTTC * 100
							} else {
								this.caution = this.infobox.I_cautionfixe 
								this.totalapaye = this.infobox.I_cautionfixe * 100
							}
							this.UIS = this.selectedsite.UI
							this.goetape5()
						  }
						  else if(response.data.Bpayplug == "1")
						  {
							//window.location.replace("https://resa.buxida.com/php/payplug.php?UIC=" + response.data.CONCUI);
						  }
						  else
						  {
							window.location.replace(response.data.urlretour);  
						  }
					} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur lors du traitement : ' + response.data.messerr,
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				}	
				}) 
			} else {
				if ((this.bcode) && (!this.bcodeok)){
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Merci de valider votre adresse e-mail',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Merci d\accepter les CGV',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				}	
			}
		},
		goetape5() {
			this.etapeencour = 5
			this.$http.post(this.APIURL + 'resa/secret',{ amount: this.totalapaye, UIC: this.UIC, UIS: this.UIS,})
			.then(response => {
				if (response.data.res === 1) {
					this.infobox = response.data.infobox[0]
					this.confirmParams.return_url = response.data.urlretour
					this.elementsOptions.clientSecret = response.data.paymentIntent.client_secret
					this.pk = response.data.pk
					this.caution = response.data.paymentIntent.amount / 100
					this.etapeencour = 6	
				} else {
					this.affrelancegostripe = true
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Erreur de connexion du serveur',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				}
			}) 
			//this.elementsOptions.clientSecret = 'pi_3LhxFpD4Tnr76noJ1zRNCkFt_secret_qp5QPitMztWc89gYltmKJw2lm'
		},	
		submit () {
		  // You will be redirected to Stripe's secure checkout page
		  this.$refs.checkoutRef.redirectToCheckout();
		},
		verifcode() {
			this.bcodeok = false
			const UIR = this.UI
			this.$http.post(this.APIURL + 'resa/verifcode',{ UI: UIR, code: this.CCODE})
			.then(response => {
				if (response.data.res === 1) {
					if (response.data.etat === 1) {
						this.bcodeok = true	
					} else {
						this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Le code est incorrecte',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
					}
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Erreur de connexion du serveur',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				}	
			}) 
		},	
		sendcode() {
			this.bcodeok = false
			const UIR = this.UI
			const UIRS= this.selectedsite.UI
			this.$http.post(this.APIURL + 'resa/sendcode',{ UI: UIR,UIS: UIRS, EMAIL: this.CEMAIL})
			.then(response => {
				if (response.data.res === 1) {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'ok',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Code : Le code est renvoyé',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
					
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Erreur de connexion du serveur',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				}	
			}) 
		},	
		onContext(ctx) {
		  this.datedebutcontrat = ctx.selectedFormatted
		},
		openurl: function (url) {   
		  window.open(url, '_blank')
		},
	},
	created() {
		//this.generatePaymentIntent()
		const UIUSER = this.UI
		this.bcode = false
		this.bcodeok = false
		this.$http.get(this.APIURL + `resa/getinfo/${UIUSER}`)
		.then(response => {
			if (response.data.res === 1) {
				const optionsstirpe = {
					pk: '',
					stripeAccount: '',
					apiVersion: '',
					locale: '',
				};
				this.site = response.data.site
				this.gsite = response.data.gsite
				this.urlcgv = this.gsite[0].C_urlretour3
				this.CEMAIL = response.data.CEMAIL
				this.CPORT = response.data.CPORT
				if (response.data.CUIBOX === "NULL") {
					this.CUIBOX = "0"
				} else {
					this.CUIBOX = response.data.CUIBOX	
				}
				if (response.data.B_code == '1') {
					this.bcode = true
				}
				if (response.data.B_codeok == '1') {
					this.bcodeok = true
				}
				this.affcentre = true
				if (this.site.length === 1 ) {
					this.selectedsite = this.site[0]	
					this.affcentre = false
					this.$http.post(this.APIURL + 'resa/getbox',{ UIR: UIUSER, UIS: this.site[0].UI })
					.then(response => {
						if (response.data.res === 1) {
							this.etape1ok = true
							this.emplacement = response.data.emplacement
							this.selectedemplacement = null
							if (this.emplacement.length != 0 ) {
								this.boxdispo = true
								if (this.CUIBOX !== '0') {
									for (var iemplacement= 0; iemplacement < this.emplacement.length; iemplacement += 1) {
										if (this.emplacement[iemplacement].UI === this.CUIBOX) {
											this.selectedemplacement = this.emplacement[iemplacement]
											this.C_numbox = this.selectedemplacement.libemplacement
											this.I_prixTTC = this.selectedemplacement.I_prixTTC
										}
									}
								}
							} else {
								this.$http.post(this.APIURL + 'resa/boxnondispo',{ UIR: UIUSER, UIS: this.site[0].UI })
								.then(response => {
									if (response.data.res != 1) {
										this.$toast({
												component: ToastificationContent,
												props: {
													title: 'Oups',
													icon: 'SmileIcon',
													variant: 'primary',
													text: 'Erreur connexion serveur',
												},
											},
											{
												timeout: 4000,
												position: 'top-center',
											})	
									}
								})
							}
						}	
						this.$http.post(this.APIURL + 'resa/getbox2',{ UIS: this.site[0].UI })
						.then(response => {
							if (response.data.res === 1) {
								this.infobox = response.data.infobox[0]
								const numberOfDaysToAdd = this.infobox.I_nbjour
								const now = new Date()
								const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
								const maxDate = new Date(today)
								// maxDate.setMonth(maxDate.getMonth() + 2)
								maxDate.setDate(maxDate.getDate() + numberOfDaysToAdd)
								this.max = maxDate
								this.affassurance = response.data.infobox[0].B_assurance
								this.C_assurance = decodeURIComponent(escape(response.data.infobox[0].C_assurance))
								if (this.affassurance) {
									this.$http.post(this.APIURL + 'resa/getassurance',{ UIR: UIUSER, UIS: this.site[0].UI })
									.then(response => {
										if (response.data.res === 1) {
											this.assurance = [];
											for(var i = 0; i < response.data.assurance.length; i++)
											{
												this.assurance.push({
													title: (response.data.assurance[i].C_libelle) + " " + response.data.assurance[i].I_prix_TTC + " " + response.data.assurance[i].C_currency + " TTC",
													UI: response.data.assurance[i].UI,
													I_prix_HT: response.data.assurance[i].I_prix_HT,
													I_prix_TTC: response.data.assurance[i].I_prix_TTC,
													C_libelle: response.data.assurance[i].C_libelle,
													C_currency: response.data.assurance[i].C_currency,
												})
											}
											this.selectedassurance = null
										}	
									}) 
								}
							}	
						}) 
					}) 
				}
			} else if ((response.data.res === 2) && (response.data.BS === 1)) {
				const optionsstirpe = {
					pk: '',
					stripeAccount: '',
					apiVersion: '',
					locale: '',
				};
				this.site = response.data.site
				this.gsite = response.data.gsite
				this.urlcgv = this.gsite[0].C_urlretour3
				this.CEMAIL = response.data.CEMAIL
				this.CPORT = response.data.CPORT
				this.UIC = response.data.CONCUI
				this.totalapaye = 0
				//this.elementsOptions.currency = 'eur' //this.infobox.C_currency
				this.caution = response.data.caution
				this.totalapaye = response.data.caution * 100
				this.UIC = response.data.cuic
				this.UIS = response.data.cuis
				this.goetape5()	
			} else if ((response.data.res === 3) && (response.data.BS === 1)) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Votre demande de paiement à echouée',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : demande non trouvée',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})
			}
		}) 
	},
	setup() {
		const { router } = useRouter()
		const UI = router.currentRoute.params.id
		//const APIURL = 'https://apibuxida-jgwob.run-eu-central1.goorm.io/'
		//const APIURL = 'https://api2.buxida.com/'
		const APIURL = 'https://api.buxida.com/'
		return {
			UI,
			APIURL,
		}
	},
}
</script>
<style lang="scss">
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
   opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.20);
  }
  100% {
    transform: scale(1);
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
